import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

import IcoDownload from "assets/icons/download-v2.svg"

const Content = () => {
  const data = [
    {
      file: withPrefix(
        "/sygnalista_formularza_zgloszenia_nieprawidlowosci.docx"
      ),
      label: "Formularz zgłoszenia nieprawidłowości",
      size: "24 KB",
    },
    {
      file: withPrefix(
        "/INFORMACJA_DLA_KANDYDATA_O_PROCEDURZE_ZGLOSZEN_WEWNETRZNYCH.docx"
      ),
      label: "Informacja dla kandydata o procedurze zgłoszeń wewnętrznych",
      size: "14 KB",
    },
  ]
  return (
    <section className="download-content">
      <div className="container-fluid">
        <div className="download-content__archive rmv-border">
          {/* <h3>Pliki do pobrania</h3> */}
          {data?.map((item, index) => (
            <a href={item?.file} target="_blank" rel="noreferrer" key={index}>
              <strong>
                {item?.label} <span>({item?.size})</span>
              </strong>{" "}
              <img src={IcoDownload} alt="" />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Content
